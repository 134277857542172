import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import HubspotForm from "~/components/HubspotForm";

const ContactUs = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Contact us"
				description="See the list of our guaranteed-to-depart bike tours in India and Asia."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/upcoming-rides",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>
			<section className="mt-6 mb-10">
				<h1 className="font-bold">Contact Us</h1>
				<p className="font-light mt-2 text-primary text-xl max-w-3xl mb-14">
					We strive to provide a timely response, usually within one business day
				</p>
				<p className="mb-10 prose font-semibold">
					Kindly submit the below form and we will promptly get in touch with you.
					{/* Alternatively, feel free to{" "}
					<a
						href="https://wa.me/917975945272"
						target="_blank"
						rel="noopener noreferrer nofollow"
						className="text-whatsapp"
					>
						WhatsApp Us
					</a>
					. */}
				</p>
				{/* You can also email us at{" "}
					<a className=" text-primary" href="mailto:contact@artofbicycletrips.com">
						contact@artofbicycletrips.com
					</a>{" "}
					for any questions or book a trip. */}

				{/* <p className="mb-10 prose">
					Alternatively,{" "}
					<a
						href="https://calendly.com/artofbicycletrips/chat-to-our-sales-team"
						target="_blank"
						rel="noopener noreferrer nofollow"
					>
						use this link to request a callback,
					</a>{" "}
					and one of our designer will gladly assist you in planning your dream ride.
				</p> */}

				{/* <h2 className="my-10">Let's Start Planning!</h2> */}
				<div className="max-w-2xl">
					<HubspotForm formID="b01e1fed-a1f4-4683-bf86-8b4c6ee1ea67" />
				</div>
				<hr className="my-10"></hr>
				<h2 className="mt-20 mb-10">Call Us</h2>
				<p className="mb-10 prose ">
					Should you wish to discuss planning your dream ride over a call, don't
					hesitate to contact us using the provided phone numbers. <br />
					<br /> If we are unable to answer your call, rest assured that we will
					promptly return your call within 1 business day.
				</p>
				{/* <p className=" text-sm text-gray-600 uppercase mb-4">
					Call Us (Mon-Fri, 9am-5pm):
				</p> */}
				<ul>
					<li>
						<p className="mb-1">
							<a href="tel:+1-707-590-5789">
								North America: <span className="underline">+1 707 590 5789</span>
							</a>
						</p>
					</li>
					<li>
						<p className="mb-1">
							<a href="tel:+447448517563">
								United Kingdom & Europe:{" "}
								<span className="underline">+44 7448517563</span>
							</a>
						</p>
					</li>
					<li>
						<p className="mb-1">
							<a href="tel:+61390284902">
								Australia & New Zealand:{" "}
								<span className="underline">+61 390284902</span>
							</a>
						</p>
					</li>
					{/* <li>
						<p className="mb-1">
							<a href="tel:+91-782-948-6953">India: +91 782 948 6953</a>
						</p>
					</li> */}
				</ul>
				{/* <section id="offices">
					<h2 className="mt-20 mb-20 uppercase">Our Offices</h2>
					<h3 className="mb-6  text-primary">Sales Offices</h3>
					<h4>London, United Kingdom</h4>
					<div className="max-w-prose">
						<p>67 Farnsworth court</p>
						<p>Osier lane</p>
						<p>London SE100RG, UK</p>
						<p>Tel: +44 7448 517563</p>
					</div>
					<h3 className="mb-6 mt-20 text-primary">Operations Offices</h3>
					<h4>Bengaluru, India</h4>
					<div className="max-w-prose">
						<p>56, 57, 4th Cross Rd, 3rd Block</p>
						<p>Ayyappa Nagar, Krishnarajapura</p>
						<p>Bengaluru, Karnataka - 560036, India</p>
						<p>Tel: +91 782 948 6953</p>
					</div>
					<h4 className="mt-10">Phnom Penh, Cambodia</h4>
					<div className="max-w-prose">
						<p>V20a, Street 59cw</p>
						<p>Borey Sopheak Magkol, Chroy Chongvar</p>
						<p>Phnom Penh, Cambodia</p>
						<p>Tel: +855 12 368 105</p>
					</div>
				</section> */}
				{/* <Helmet>
					<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
				</Helmet>
				<iframe
					class="airtable-embed airtable-dynamic-height"
					src="https://airtable.com/embed/shrJK2IBkNl1dL95K?backgroundColor=green"
					frameborder="0"
					onmousewheel=""
					width="100%"
					height="927"
					//style="background: transparent; border: 1px solid #ccc;"
				></iframe> */}
				{/* <div className="relative max-w-3xl pb-form">
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLSeSxbFT3aDSvcKcctYxEtGLX_yKt9kxX6JHgifpUDXx2HuhzQ/viewform?embedded=true"
						width="100%"
						height="100%"
						frameborder="0"
						marginheight="0"
						marginwidth="0"
						className="absolute w-full h-full"
					>
						Loading…
					</iframe>
					<
				</div> */}
				{/* <div className="max-w-xs sm:max-w-lg md:max-w-xl lg:max-w-2xl">
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLSeSxbFT3aDSvcKcctYxEtGLX_yKt9kxX6JHgifpUDXx2HuhzQ/viewform?embedded=true"
						width="100%"
						height="1250"
						frameborder="0"
						marginheight="0"
						marginwidth="0"
						//className="absolute w-full h-full"
					>
						Loading…
					</iframe>
				</div> */}
				{/* <PopupButton
					id="SKjDwiLC"
					className="bg-primary py-2 px-2 sm:px-4 rounded-full text-white mr-4"
				>
					Fill Form
				</PopupButton> */}
			</section>
		</Layout>
	);
};

export default ContactUs;
